<template>
  <div id="listbox">
    <img src="@/assets/img/Special/post2.png" />
    <ul class="lists">
      <li v-for="(item, index) in lists" :key="item.id">
        <div class="header">
          <!-- <img src="@/assets/img/Special/code.png" /> -->
          <div class="omit">{{ item.gcName }}</div>
          <div>NO.{{ index + 1 }}</div>
        </div>
        <!-- <div class="info">
          <div>订单号</div>
          <div class="omit">{{ item.ordNo }}</div>
        </div> -->
        <!-- gcShape 0：卡号卡密 1：卡密 2：卡号短链 3：卡号短链验证码 -->
        <template v-if="[0].includes(item.gcShape)">
          <div class="info">
            <div>卡&nbsp;&nbsp;&nbsp;号</div>
            <div class="omit">{{ item.cardNo }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.cardNo"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
          <div class="info">
            <div>密&nbsp;&nbsp;&nbsp;码</div>
            <div class="omit">{{ item.cardPwd }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.cardPwd"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
        </template>
        <div class="info" v-if="[1].includes(item.gcShape)">
          <div>卡&nbsp;&nbsp;&nbsp;密</div>
          <div class="omit">{{ item.cardPwd }}</div>
          <div
            class="btn"
            v-clipboard:copy="item.cardPwd"
            v-clipboard:success="copySuccess"
            v-clipboard:error="copyError"
          >
            复制
          </div>
        </div>
        <div class="info" v-if="[2].includes(item.gcShape)">
          <div>短&nbsp;&nbsp;&nbsp;链</div>
          <div class="omit">{{ item.shortUrl }}</div>
          <div
            class="btn"
            v-clipboard:copy="item.shortUrl"
            v-clipboard:success="copySuccess"
            v-clipboard:error="copyError"
          >
            复制
          </div>
        </div>
        <template v-if="[3].includes(item.gcShape)">
          <div class="info" v-show="item.cardNo">
            <div>卡&nbsp;&nbsp;&nbsp;号</div>
            <div class="omit">{{ item.cardNo }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.cardNo"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
          <div class="info">
            <div>短&nbsp;&nbsp;&nbsp;链</div>
            <div class="omit">{{ item.shortUrl }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.shortUrl"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
          <div class="info">
            <div>密&nbsp;&nbsp;&nbsp;码</div>
            <div class="omit">{{ item.verifyCode }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.verifyCode"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import { listRedeemCode } from '@/api/Special.js'
export default {
  data() {
    return {
      lists: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const { code, data, message } = await listRedeemCode(this.$route.query)
      if (code === 0) {
        this.lists = data
      } else {
        this.$toast.fail({
          message: message
        })
      }
    },
    copySuccess() {
      this.$toast({
        message: '复制成功'
      })
    },
    copyError() {
      this.$toast({
        message: '复制失败'
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/Special.less';
</style>
